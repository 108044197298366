
import { mdiMenuDown } from '@mdi/js'

export default {
  async asyncData({ $kaiden }) {
    const courseCatalog = await $kaiden.$get(`/Website/course-catalog`)
    return { courseCatalog }
  },

  data() {
    return {
      mdiMenuDown,
      input: '',
      filteredQuestions: [],
    }
  },

  head() {
    return {
      title: 'Course Catalog',
      titleTemplate: '%s | Great Canadian Training',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Browse our available courses. See upcoming class dates. Learn more about each course. Register online.',
        },
      ],
      }
  },

  computed: {
    microsoftLandingPages() {
      return this.courseCatalog
        .filter((x) => x.landingPageCategories.includes('Microsoft'))
        .sort((a, b) => (a.landingPageTitle > b.landingPageTitle ? 1 : -1))
    },
    adobeLandingPages() {
      return this.courseCatalog
        .filter((x) => x.landingPageCategories.includes('Adobe'))
        .sort((a, b) => (a.landingPageTitle > b.landingPageTitle ? 1 : -1))
    },
    accessibilityLandingPages() {
      return this.courseCatalog
        .filter((x) => x.landingPageCategories.includes('Accessibility'))
        .sort((a, b) => (a.landingPageTitle > b.landingPageTitle ? 1 : -1))
    },
    professionalSkillsLandingPages() {
      return this.courseCatalog
        .filter((x) => x.landingPageCategories.includes('Professional Skills'))
        .sort((a, b) => (a.landingPageTitle > b.landingPageTitle ? 1 : -1))
    },
    programmingAndSpecialtyLandingPages() {
      return this.courseCatalog
        .filter((x) =>
          x.landingPageCategories.includes('Programming & Specialty')
        )
        .sort((a, b) => (a.landingPageTitle > b.landingPageTitle ? 1 : -1))
    },
    governmentLandingPages() {
      return this.courseCatalog
        .filter((x) => x.landingPageCategories.includes('Government'))
        .sort((a, b) => (a.landingPageTitle > b.landingPageTitle ? 1 : -1))
    },

    size() {
      const size = { xs: 'default', sm: 'default', lg: '', xl: '' }[
        this.$vuetify.breakpoint.name
      ]
      return size ? { [size]: true } : {}
    },

    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get() {
        return this.$route.query.tab
      },
    },
  },
  methods: {
    deliveryMethod(inPerson, virtual) {
      if (inPerson === true && virtual === true) return 'Hybrid'
      else if (inPerson === true) return 'In Person'
      else return 'Live Instructor-Led Online'
    },

    checkIcon(icon) {
      if (icon.includes('fr-'))
        return icon.replace('fr-', '').replace('.png', '.webp')
      else return icon.replace('.png', '.webp')
    },
  },
}
