
import { mdiEmailCheck, mdiEmailNewsletter } from '@mdi/js'
import sanitize from '~/mixins/sanitize.js'

export default {
  mixins: [sanitize],
  data() {
    return {
      mdiEmailNewsletter,
      mdiEmailCheck,
      confirmation: false,
      spinner: false,
      snackbar: false,
      snackMessage: '',
      name: '',
      email: '',
      nameRules: [
        (n) => !!n || 'Name is required',
        (n) => (n && !!n.trim()) || 'Provide a Valid Name',
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'Provide a valid E-mail',
      ],
    }
  },
  methods: {
    async newsletterRegistration() {
      this.spinner = true

      const newsletterRequest = {
        Name: this.sanitize(this.name),
        Email: this.email,
      }

      await setTimeout(() => {
        this.$kaiden
          .post('Website/newsletter-registration', newsletterRequest)
          
          .then((res) => {
          
          if (res.status === 200) {
             
             this.confirmation = true
             
             this.spinner = false
             
             this.$gtm.push({
                event: 'newsletter_signup',
                currency: 'CAD',
                value: 30
              })


            }
          })
      }, 2500)
    },
    async formValidation() {
      const element = document.getElementsByClassName('newsletter-form')
      await window.scrollTo({
        top: element[0].offsetTop + 80,
        left: 0,
        behavior: 'smooth',
      })

      if (this.$refs.form.validate()) {
        this.newsletterRegistration()
      } else {
        this.snackbar = true
        this.snackMessage = 'Please add proper contact information'
      }
    },
    snackState() {
      if (this.snackbar === true) this.snackbar = false
    },
  },
}
